import React from 'react';
import { FaSquareFacebook, FaSquareInstagram, FaLinkedin, FaSquareYoutube, FaSquareReddit } from "react-icons/fa6";

import '../../components/soon/CommingSoon.css'; // Import the CSS file for styling
import Logo from '../../assets/images/Logo White - uxheaven.svg'; // Adjust the path if necessary

const CommingSoon = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <img src={Logo} alt="Logo" className="hero-logo" />
        <h1 className="hero-heading">Launching Soon</h1>
        <p className="hero-description">
        Thank you for visiting UXHeaven! This is a curated library, that can help you find relevant information 
        on your UX journey! We care deeply about the quality and relevance of resources. Most of the materials here have
         been personally tested or recommended by people we trust.
        </p>
        <p className="hero-email">Fore More details, write us at  - <a href="mailto:admin@uxheaven.in">admin@uxheaven.in</a></p>
        {/* <div className="social-icons">
          <a href="https://facebook.com" className="social-icon"><FaSquareFacebook /></a>
          <a href="https://twitter.com" className="social-icon"><FaSquareInstagram /></a>
          <a href="https://instagram.com" className="social-icon"><FaLinkedin /></a>
          <a href="https://linkedin.com" className="social-icon"><FaSquareYoutube /></a>
          <a href="https://youtube.com" className="social-icon"><FaSquareReddit /></a>
        </div> */}
      </div>
    </div>
  );
}

export default CommingSoon;